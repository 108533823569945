import { Button } from "@mui/material";
import { blue, green, orange, pink, red } from "@mui/material/colors";
import { Container } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import CustomDate from "../components/CustomDate";
import FullDialog from "../components/FullDialog";
import StatusComponent from "../components/StatusComponent";
import { UserSelectList } from "../components/UserSelectList";
import { AuthContext } from "../context/AuthContext";
import { SalesContext } from "../context/SalesContext";
import { UsersContext } from "../context/UsersContext";
import getGroupSales from "../functions/getGroupSales";
import getTitle from "../functions/getTitle";
import loadSales from "../functions/loadSales";
import loadUsers from "../functions/loadUsers";
import { GridContainer, GridDivider, GridFlexBox, IconC, Loading, Name, Status, Title } from "../themes/themes";
import formatNumber from "../utils-functions/formatNumber";
import getDayMonthYear from "../utils-functions/getDayMonthYear";
import getStartAndEndDate from "../utils-functions/getStartAndEndDate";

const DATE = new Date();
const MONTH = parseInt(getDayMonthYear(DATE).month);
const YEAR = parseInt(getDayMonthYear(DATE).year);

export default function GroupSalesPage() {
    const { sales, setSales, yearsLoaded } = useContext(SalesContext);
    const { users, setUsers } = useContext(UsersContext);
    const { user } = useContext(AuthContext);
    const [groupSales, setGroupSales] = useState();
    const [loading, setLoading] = useState(false);
    const [agentList, setAgentList] = useState(users);
    const [status, setStatus] = useState(["BOOKED", "BILLED", "TO-INVOICE"]);
    const [selectedUser, setSelectedUser] = useState(user);
    const [month, setMonth] = useState(MONTH);
    const [year, setYear] = useState(YEAR);
    const [display, setDisplay] = useState("year");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [openCustomDate, setOpenCustomDate] = useState(false);
    const [type, setType] = useState(["project", "subsale"]);

    useEffect(() => {
        const getSales = async () => {
            setLoading(true);
            const sales = await loadSales(user, year, year, yearsLoaded);
            setLoading(false);
            setSales(sales);
        };

        const getUsers = async () => {
            setLoading(true);
            const users = await loadUsers(user);
            console.log("Loading users for ", user.agent);
            setUsers(users);
            setAgentList(users);
            setLoading(false);
        };

        if (!sales) getSales();
        if (!users) getUsers();
    }, []);

    useEffect(() => {
        let dates = {};
        if (display === "month") {
            dates = getStartAndEndDate(month, year);
        } else if (display === "year") {
            dates = {
                start: year + "-01-" + "01",
                end: year + "-12-" + "31T23:59:59"
            };
        }
        if (startDate) {
            dates = { start: startDate, end: endDate + "T23:59:59" };
        }
        console.log(dates);
        let sUser = selectedUser ? selectedUser : user;
        const getData = async () => {
            const groupSalesArray = await getGroupSales(
                sales,
                sUser,
                new Date(dates.start),
                new Date(dates.end),
                type,
                status,
                users
            );
            setGroupSales(groupSalesArray);
        };
        getData();
    }, [sales, users, status, selectedUser, month, display, startDate, type]);

    const handleSelect = (value) => {
        setSelectedUser(value);
    };

    const handleCustomDate = async () => {
        setOpenCustomDate(true);
    };

    const handleBack = async () => {
        setDisplay("month");
        if (month === 1) {
            setMonth(12);
            const newYear = year - 1;
            setYear(newYear);
            setLoading(true);
            const sales2 = await loadSales(user, newYear, newYear, yearsLoaded);
            if (sales2) {
                const newSales = [...sales, ...sales2];
                setSales(newSales);
            }
            setLoading(false);
            return;
        }
        setMonth(month - 1);
    };

    const handleForward = () => {
        setDisplay("month");
        if (month === 12) {
            setMonth(1);
            setYear(year + 1);
            return;
        }
        setMonth(month + 1);
    };

    const clearCustomDates = () => {
        setStartDate();
        setEndDate();
    };

    const handleChangeDisplay = () => {
        setStartDate();
        setEndDate();
        if (display === "month") {
            setDisplay("year");
        } else setDisplay("month");
    };

    const setCustomDate = (response) => {
        setOpenCustomDate(false);
        if (response) {
            setStartDate(response.start);
            setEndDate(response.end);
        }
    };

    const checkType = (value) => {
        if (type.includes(value)) return "true";
        return;
    };

    const handleClickType = (value) => {
        switch (value) {
            case "project":
                updateState(["project"], setType, type);
                break;
            case "subsale":
                updateState(["subsale"], setType, type);
                break;
            case "rental":
                updateState(["rental"], setType, type);
                break;
            default:
                return;
        }
    };

    function updateState(s, setStatusFunction, state) {
        let newState = [...state];

        s.forEach((a) => {
            if (state.includes(a)) {
                let index = newState.findIndex((b) => b === a);
                newState.splice(index, 1);
            } else {
                newState = [...newState, a];
            }
        });

        setStatusFunction(newState);
    }

    return (
        <Container maxWidth="sm" disableGutters>
            <GridContainer padding={"8px"}>
                <GridFlexBox>
                    <Title>Leaders Group Sales</Title>
                </GridFlexBox>
                <GridFlexBox>
                    {!startDate ? (
                        <>
                            <IconC onClick={handleBack} cp>
                                arrow_back
                            </IconC>
                            {display === "month" && <Name>{month}</Name>}
                            {display === "month" && <Name>{" | "}</Name>}
                            <Name>{year}</Name>
                            <IconC onClick={handleForward} cp>
                                arrow_forward
                            </IconC>
                        </>
                    ) : (
                        <>
                            <Name wrap fs12>
                                {startDate}
                            </Name>
                            <Name wrap fs12>
                                {endDate}
                            </Name>
                            <IconC onClick={clearCustomDates}>clear</IconC>
                        </>
                    )}
                    <Button size="small" onClick={handleChangeDisplay}>
                        {display === "month" ? "By Year" : "By Month"}
                    </Button>
                    <Button size="small" onClick={handleCustomDate}>
                        Custom Date
                    </Button>
                </GridFlexBox>
                <GridFlexBox>
                    <StatusComponent status={status} setStatus={setStatus} bgColor={blue[500]}>
                        Booked
                    </StatusComponent>
                    <StatusComponent status={status} setStatus={setStatus} bgColor={green[700]}>
                        Converted
                    </StatusComponent>
                    <StatusComponent status={status} setStatus={setStatus} bgColor={red[700]}>
                        Cancelled
                    </StatusComponent>
                </GridFlexBox>
                <GridFlexBox xs={12} md={6}>
                    <Status
                        clicked={checkType("project")}
                        onClick={() => handleClickType("project")}
                        bgcolor={orange[500]}
                    >
                        Projects
                    </Status>
                    <Status
                        clicked={checkType("subsale")}
                        onClick={() => handleClickType("subsale")}
                        bgcolor={pink[500]}
                    >
                        Subsales
                    </Status>
                </GridFlexBox>
                <GridFlexBox>
                    <UserSelectList selectedUser={selectedUser} users={agentList} handleSelect={handleSelect} />
                </GridFlexBox>
                <Loading loading={loading} />
                <GridDivider ptb={10} />
                <GridFlexBox w="9%" fe p0>
                    <Name bold fs13>
                        No.
                    </Name>
                </GridFlexBox>
                <GridFlexBox w="9%" fe p0>
                    <Name bold fs10>
                        Title
                    </Name>
                </GridFlexBox>
                <GridFlexBox w="30%" fs p0>
                    <Name bold fs13>
                        Name
                    </Name>
                </GridFlexBox>
                <GridFlexBox w="24%" fe p0>
                    <Name bold fs13>
                        Group
                    </Name>
                </GridFlexBox>
                <GridFlexBox w="28%" fe p0>
                    <Name bold fs13>
                        Personal
                    </Name>
                </GridFlexBox>

                {groupSales &&
                    groupSales.map((groupSale, i) => (
                        <React.Fragment key={i}>
                            <GridFlexBox w="9%" fe p0>
                                <Name fs10>{i + 1}</Name>
                            </GridFlexBox>
                            <GridFlexBox w="9%" p0>
                                <Name fs10>{getTitle(groupSale.email, users)}</Name>
                            </GridFlexBox>
                            <GridFlexBox w="30%" fs p0>
                                <Name nowrap fs12>
                                    {groupSale.name}
                                </Name>
                            </GridFlexBox>
                            <GridFlexBox w="24%" fe p0>
                                <Name fs12>{formatNumber(groupSale.groupValue, 0)}</Name>
                            </GridFlexBox>
                            <GridFlexBox w="28%" fe p0>
                                <Name fs12>{formatNumber(groupSale.personalValue, 0)}</Name>
                            </GridFlexBox>
                        </React.Fragment>
                    ))}
            </GridContainer>
            <FullDialog
                open={openCustomDate}
                handleClose={setCustomDate}
                title={"Set Custom Date"}
                Component={<CustomDate handleClose={setCustomDate} />}
            />
        </Container>
    );
}

import StarIcon from "@mui/icons-material/Star";
import { Button, Chip, Container, MenuItem, Select } from "@mui/material";
import { blue, green, orange, red } from "@mui/material/colors";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { campaigns_2024 } from "../campaigns/campaigns";
import mapSalesToCampaigns from "../campaigns/mapSalesToCampaigns";
import { AuthContext } from "../context/AuthContext";
import { ProjectsContext } from "../context/ProjectsContext";
import { SalesContext } from "../context/SalesContext";
import getProjects from "../functions/getProjects";
import loadSales from "../functions/loadSales";
import { GridContainer, GridDivider, GridFlexBox, Loading, Name, Title } from "../themes/themes";
import convertDate from "../utils-functions/convertDate";
import formatDate from "../utils-functions/formatDate";
import formatNumber from "../utils-functions/formatNumber";
import getDayMonthYear from "../utils-functions/getDayMonthYear";

const DATE = new Date();
const YEAR = parseInt(getDayMonthYear(DATE).year);
const CAMPAIGNS = campaigns_2024;

export default function CampaignsPage() {
    const { user } = useContext(AuthContext);
    const { sales, setSales, yearsLoaded } = useContext(SalesContext);
    const { projects, setProjects } = useContext(ProjectsContext);

    const [year, setYear] = useState(YEAR);
    const [loading, setLoading] = useState(false);
    const [campaigns, setCampaigns] = useState(CAMPAIGNS);
    const [showDetails, setShowDetails] = useState(false);
    const [campaignName, setCampaignName] = useState();
    const [loadingDetails, setLoadingDetails] = useState(false);

    useEffect(() => {
        const getSalesData = async () => {
            setLoading(true);
            const sales = await loadSales(user, year, year, []);
            await getProjectsData(sales);
            setSales(sales);
            setLoading(false);
        };

        const getProjectsData = async (sales) => {
            const projects = await getProjects(sales);
            setProjects(projects);
        };

        getSalesData();
        getProjectsData(sales);
    }, [year]);

    const handleChangeCampaign = (event) => {
        setCampaignName(event.target.value);
    };

    const mappedCampaigns = useMemo(() => {
        const mappedCampaigns = mapSalesToCampaigns(sales, campaigns);
        if (campaignName) {
            const displayCampaign = mappedCampaigns.filter((c) => c.name === campaignName);
            return displayCampaign;
        } else {
            return [];
        }
    }, [sales, campaignName]);

    return (
        <Container maxWidth="md">
            <GridContainer>
                <GridFlexBox w="60%">
                    <Title>Campaigns Page</Title>
                </GridFlexBox>
                <GridFlexBox w="40%" fe>
                    {loadingDetails && <Loading loading={loadingDetails} />}
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            // delay for 1 second
                            setLoadingDetails(true);
                            setShowDetails(!showDetails);
                            setTimeout(() => {
                                setLoadingDetails(false);
                            }, 1000);
                        }}
                        style={{ textTransform: "none", paddingTop: 0, paddingBottom: 0 }}
                        disabled={loadingDetails}
                    >
                        {showDetails ? "Hide Details" : "Show Details"}
                    </Button>
                </GridFlexBox>
                <GridFlexBox fs>
                    <Select value={year} onChange={(e) => setYear(e.target.value)} placeholder="Select year" fullWidth>
                        <MenuItem value={2024}>2024</MenuItem>
                        <MenuItem value={2025}>2025</MenuItem>
                    </Select>
                </GridFlexBox>
                <GridFlexBox fs>
                    <Select
                        value={campaignName}
                        onChange={handleChangeCampaign}
                        placeholder="Select a campaign"
                        fullWidth
                    >
                        {campaigns.map((c) => (
                            <MenuItem key={c.name} value={c.name}>
                                {c.name}
                            </MenuItem>
                        ))}
                    </Select>
                </GridFlexBox>
                <GridDivider />
                <Loading loading={loading} />
                {mappedCampaigns?.map((campaign) => (
                    <React.Fragment key={campaign.name}>
                        <GridFlexBox fs w="40%">
                            <Chip label={campaign.name} sx={{ width: "100%" }} />
                        </GridFlexBox>
                        <GridFlexBox fe w="20%">
                            <Name>Target:</Name>
                        </GridFlexBox>
                        <GridFlexBox fe w="20%">
                            <Name bold>
                                {formatNumber(campaign.number || campaign.value, 0)}
                                {campaign.number ? " units" : ""}
                            </Name>
                        </GridFlexBox>
                        <GridFlexBox w="20%"></GridFlexBox>
                        <GridFlexBox fs>
                            <Name color={blue[500]}>
                                {formatDate(campaign.startDate)} to {formatDate(campaign.endDate)} Conversion:{" "}
                                {campaign.conversion} days
                            </Name>
                        </GridFlexBox>
                        <GridFlexBox fs w="31%">
                            <Name>Name</Name>
                        </GridFlexBox>
                        <GridFlexBox fe w="20%">
                            <Name color={blue[500]}>Booked</Name>
                        </GridFlexBox>
                        <GridFlexBox fe w="20%">
                            <Name color={green[500]}>{`Converted (+Booster)`}</Name>
                        </GridFlexBox>
                        <GridFlexBox fe w="20%">
                            <Name color={green[500]}>{`On time (+Booster)`}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="9%"></GridFlexBox>
                        {campaign.agentsArray?.map((agent) => (
                            <React.Fragment key={agent.name}>
                                <GridFlexBox fs w="31%">
                                    <Name bold>{agent.name}</Name>
                                </GridFlexBox>
                                <GridFlexBox fe w="20%">
                                    <Name color={blue[500]}>
                                        {campaign.number
                                            ? formatNumber(agent.totalBookedNumber, 2)
                                            : formatNumber(agent.totalBooked, 0)}
                                    </Name>
                                </GridFlexBox>
                                <GridFlexBox fe w="20%">
                                    <Name color={green[500]}>
                                        {campaign.number
                                            ? formatNumber(agent.totalConvertedNumber, 2)
                                            : formatNumber(agent.totalConverted, 0)}
                                    </Name>
                                </GridFlexBox>
                                <GridFlexBox fe w="20%">
                                    <Name color={green[500]}>
                                        {campaign.number
                                            ? formatNumber(agent.totalConvertedNumberOntime, 2)
                                            : formatNumber(agent.totalConvertedOntime, 0)}
                                    </Name>
                                </GridFlexBox>
                                <GridFlexBox w="9%" fe>
                                    {checkedAchieved(agent, campaign)}
                                </GridFlexBox>

                                {showDetails &&
                                    agent.sales.map((sale) => (
                                        <React.Fragment key={sale.id}>
                                            <GridFlexBox fs w="18%">
                                                <Name onClick={() => console.log(sale)} fs10>
                                                    {formatDate(convertDate(sale.bookingDate))}
                                                </Name>
                                            </GridFlexBox>
                                            <GridFlexBox fs w="20%">
                                                <Name>{sale.project}</Name>
                                            </GridFlexBox>
                                            <GridFlexBox fe w="17%">
                                                <Name>{sale.unitNo}</Name>
                                            </GridFlexBox>
                                            <GridFlexBox fe w="5%">
                                                <Name
                                                    color={sale.multiplier > 1 && red[500]}
                                                >{`x${sale.multiplier}`}</Name>
                                            </GridFlexBox>
                                            <GridFlexBox fe w="20%">
                                                <Name>{formatNumber(sale.netPricePortion, 0)}</Name>
                                            </GridFlexBox>
                                            <GridFlexBox fe w="20%">
                                                <Name
                                                    fs10
                                                    bold
                                                    color={sale.status === "BOOKED" ? blue[500] : green[500]}
                                                >
                                                    {sale.status}
                                                </Name>
                                            </GridFlexBox>
                                        </React.Fragment>
                                    ))}

                                <GridDivider />
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </GridContainer>
        </Container>
    );
}

const checkedAchieved = (agent, campaign) => {
    if (!campaign.number) {
        return agent.totalConvertedOntime >= campaign.value ? <StarIcon sx={{ color: orange[500] }} /> : "";
    } else {
        return agent.totalConvertedNumber >= campaign.number ? <StarIcon sx={{ color: orange[500] }} /> : "";
    }
};
